import Typography from "typography"
import Fairy from "typography-theme-fairy-gates"
import Wordpress from "typography-theme-wordpress-2016"

// Fairy.overrideThemeStyles = ({ rhythm }, options) => ({
//   "a": {
//     backgroundImage: "none"
//   }
// })

const typography = new Typography(Fairy)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
